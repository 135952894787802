import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "Interpret",
  index: "4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductionTemplate = _resolveComponent("ProductionTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProductionTemplate, {
      introduce: _ctx.introduce,
      "server-data": {
          qrCodeSrc: require('../../assets/qrcode-2.jpg'),
          wechatUserName: 'metasota07',
          email: 'wutingting@metasota.ai',
          phoneNumber: '19211034418'
        }
    }, null, 8, ["introduce", "server-data"])
  ]))
}